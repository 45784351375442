import {HttpTransportType, HubConnectionBuilder, LogLevel} from '@microsoft/signalr';
import {useEffect, useRef} from 'react';

function ifExists(handler) {
  return (...args) => {
    if (handler && handler.current && typeof handler.current === 'function') {
      return handler.current(...args);
    }
  };
}

export function useSockets({onData, onError, onConnect, onClose}) {
  const dataHandler = useRef(onData);
  const errorHandler = useRef(onError);
  const connectHandler = useRef(onConnect);
  const closeHandler = useRef(onClose);

  useEffect(() => {
    dataHandler.current = onData;
    errorHandler.current = onError;
    connectHandler.current = onConnect;
    closeHandler.current = onClose;
  }, [onData, onError, onConnect, onClose]);

  const connection = useRef();

  useEffect(() => {
    connection.current = new HubConnectionBuilder()
      .withUrl('/ws', {accessTokenFactory: () => localStorage.getItem('token'), transport: HttpTransportType.WebSockets, skipNegotiation: true})
      .configureLogging(LogLevel.Information)
      .build();

    connection.current.start()
      .then(ifExists(connectHandler))
      .catch(ifExists(errorHandler));

    connection.current.on('status', ifExists(dataHandler));

    connection.current.onclose(ifExists(closeHandler));

    return () => {
      ifExists(closeHandler)();

      if (connection && connection.current) {
        connection.current.stop();
        connection.current = null;
      }
    };
  }, []);
}
