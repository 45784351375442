import React from 'react';
import {Input} from '../../components/Input';
import PropTypes from 'prop-types';
import {createSite, deleteSite} from '../../api/site';
import styles from './index.css';

EditPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export function EditPage({dispatch, data, actions}) {
  const site = data.site || {};

  const handleSubmit = e => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const request = {};

    for (let [key, value] of formData.entries()) {
      if (key === 'id') {
        value = parseInt(value);
      }

      request[key] = value;
    }

    createSite(request)
      .then(x => {
        dispatch(actions.updateData(x));
      })
      .catch(err => {
        console.error(err);
        dispatch(actions.setError('Failed to create site. Please try again.'));
      });
  };

  const handleDelete = e => {
    e.preventDefault();

    return deleteSite(site.siteID)
      .then(res => dispatch(actions.updateData(res)))
      .catch(err => {
        console.error(err);
        dispatch(actions.setError('Failed to delete site. Please try again.'));
      });
  };

  return <main>
    <h1 className='sr-only'>Edit Sites</h1>
    <button onClick={() => dispatch(actions.switchView('status'))} className={styles.backButton}>{'← back'}</button>

    {site.siteID ? <button onClick={handleDelete} className={styles.deleteButton}>delete</button> : null}

    <form onSubmit={handleSubmit}>
      <input type='hidden' name='id' value={site.siteID} />

      <Input name='name' label='name' placeholder='e.g. io' required defaultValue={site.site} />
      <Input name='environment' label='environment' placeholder='e.g. stage5' required defaultValue={site.environment} />
      <Input name='url' label='url' placeholder='e.g. https://stage5.brainstation.io' required defaultValue={site.url} />
      <Input name='ecsURL' label='ecs url' placeholder='e.g. https://console.aws.amazon.com/ecs/home?region=us-east-1#/clusters/stage5-windows-cluster/services/stage5-assets-api-service/details' required defaultValue={site.ecsURL} />
      <Input name='raygunURL' label='raygun url' placeholder='e.g. https://app.raygun.com/crashreporting/1n6mkf1?#active' required defaultValue={site.raygunURL} />

      <button type="submit" className={styles.saveButton}>save</button>
    </form>
  </main>;
}
