import React, {useRef} from 'react';
import {Input} from '../../components/Input';
import PropTypes from 'prop-types';

LoginPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
};

export function LoginPage({dispatch, actions}) {
  const ref = useRef();

  const handleSubmit = e => {
    e.preventDefault();

    const token = ref.current.value;

    dispatch(actions.setToken(token));
  };

  return <main>
    <h1 className='sr-only'>Login</h1>
    <form onSubmit={handleSubmit}>
      <Input label='credentials' ref={ref} required />
      <button type="submit">login</button>
    </form>
  </main>;
}
