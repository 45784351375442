import React, {useEffect, useReducer} from 'react';
import {createAction, switchExpression} from '../../helpers/data';
import {StatusPage} from '../../pages/Status';
import {LoginPage} from '../../pages/Login';
import {EditPage} from '../../pages/Edit';
import {getStatus} from '../../api/status';
import styles from './index.css';

const [UPDATE_DATA, updateData] = createAction('UPDATE_DATA');
const [SWITCH_VIEW, switchView] = createAction('SWITCH_VIEW');
const [SET_ERROR, setError] = createAction('SET_ERROR');
const [EDIT_SITE, editSite] = createAction('EDIT_SITE');
const [SET_TOKEN, setToken] = createAction('SET_TOKEN');
const [SET_CONNECTED, setConnected] = createAction('SET_CONNECTED');

const actions = {
  updateData,
  switchView,
  setError,
  editSite,
  setToken,
  setConnected,
};

const reducer = {
  [SWITCH_VIEW]: (state, action) => ({
    ...state,
    error: null,
    view: action.value,
    site: null,
  }),
  [UPDATE_DATA]: (state, action) => ({
    ...state,
    error: null,
    sites: action.value,
    view: 'status',
    site: null,
  }),
  [SET_ERROR]: (state, action) => ({
    ...state,
    error: action.value,
  }),
  [EDIT_SITE]: (state, action) => ({
    ...state,
    view: 'edit',
    site: action.value,
  }),
  [SET_TOKEN]: (state, action) => {
    localStorage.setItem('token', action.value);

    return {
      ...state,
      view: 'status',
      token: action.value,
    };
  },
  [SET_CONNECTED]: (state, action) => ({
    ...state,
    connected: action.value,
  }),
};

const initialState = {
  error: null,
  view: 'status',
  sites: null,
  site: null,
  token: localStorage.getItem('token'),
  connected: false,
};

export function App() {
  const [state, dispatch] = useReducer((state, action) => Object.prototype.hasOwnProperty.call(reducer, action.type) ?
    reducer[action.type](state, action) :
    state, initialState);

  useEffect(() => {
    if (!state.token) {
      return;
    }

    getStatus()
      .then(data => {
        dispatch(updateData(data));
      })
      .catch(err => {
        console.error(err);
        dispatch(setError('Failed to get data.'));
      });
  }, [state.token]);

  return <div>
    {state.token ? switchExpression(state.view, {
      status: <StatusPage dispatch={dispatch} data={state} actions={actions} />,
      edit: <EditPage dispatch={dispatch} data={state} actions={actions} />,
    }) : <LoginPage dispatch={dispatch} actions={actions} />}

    <div className={styles.connectedState}>{state.connected ? 'connected' : 'not connected'}</div>
  </div>;
}
