import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Environment} from '../../components/Environment';
import styles from './index.css';
import {useSockets} from '../../helpers/useSockets';

// Assumes sorted by api already

function segmentByEnvironments(sites) {
  let output = [];

  let currentEnvironment;
  let current = [];

  for (let site of sites) {
    if (!currentEnvironment) {
      currentEnvironment = site.environment;
      current.push(site);
      continue;
    }

    if (currentEnvironment !== site.environment) {
      output.push(current);
      current = [site];
      currentEnvironment = site.environment;
      continue;
    }

    current.push(site);
  }

  if (current.length) {
    output.push(current);
  }

  return output;
}

/**
 * Builds a favicon that represents the current status of all the sites
 * A red circle if any site is failed
 * A green circle if all sites are valid
 * A blue circle if any site is deploying
 * @param {array} sites   List of all current site statuses
 * @returns {undefined}
 */
function updateFavicon(sites) {
  if (!sites || !sites.length) {
    return;
  }

  const failed = sites.some(x => x.status.includes('failed'));
  const valid = sites.every(x => x.status === 'deployed' || x.status === 'never_built');

  const canvas = document.createElement('canvas');

  canvas.width = 64;
  canvas.height = 64;

  canvas.style.visibility = 'hidden';
  canvas.style.position = 'absolute';
  canvas.style.top = '-10000px';
  canvas.style.left = '-10000px';

  document.body.append(canvas);

  const context = canvas.getContext('2d');

  context.fillStyle = failed ? '#f76262' : valid ? '#4dd599' : 'lightskyblue';

  context.ellipse(32.5, 32.5, 32, 32, 2 * Math.PI, 0 , 2 * Math.PI);
  context.fill();

  const image = canvas.toDataURL('image/png');
  const favicon = document.createElement('link');

  favicon.rel = 'shortcut icon';
  favicon.id = 'favicon';
  favicon.href = image;


  const last = document.getElementById('favicon');

  if (last) {
    last.remove();
  }

  document.head.append(favicon);

  canvas.remove();
}


StatusPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export function StatusPage({dispatch, data, actions}) {
  useSockets({
    onData: statuses => dispatch(actions.updateData(statuses)),
    onError: err => dispatch(actions.setError(err)),
    onConnect: () => dispatch(actions.setConnected(true)),
    onClose: () => dispatch(actions.setConnected(false)),
  });

  useEffect(() => {
    updateFavicon(data.sites);
  }, [data.sites]);

  // Loading
  if (data.sites === null) {
    return null;
  }


  const sitesByEnvironment = segmentByEnvironments(data.sites);

  return <main>
    <h1 className='sr-only'>Status</h1>
    <button onClick={() => dispatch(actions.switchView('edit'))} className={styles.addSite}>+ add site</button>

    {sitesByEnvironment.map(sites => {
      const environment = sites[0].environment;

      return <Environment sites={sites} name={environment} key={environment} dispatch={dispatch} actions={actions} />;
    })}
  </main>;
}
