function handleError(response) {
  if (!response.success) {
    throw new Error(response.error);
  }

  return response.payload;
}

export function getRequest(url) {
  return fetch(new Request(url), {
    credentials: 'include',
    headers: {
      'X-Api-Key': localStorage.getItem('token'),
    },
  })
    .then(x => x.json())
    .then(handleError);
}

export function postRequest(url, body) {
  return fetch(new Request(url), {
    credentials: 'include',
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Api-Key': localStorage.getItem('token'),
    }),
    body: JSON.stringify(body),
  })
    .then(x => x.json())
    .then(handleError);
}

export function deleteRequest(url) {
  return fetch(new Request(url), {
    credentials: 'include',
    method: 'DELETE',
    headers: {
      'X-Api-Key': localStorage.getItem('token'),
    },
  })
    .then(x => x.json())
    .then(handleError);
}
