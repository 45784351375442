import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import classNames from 'classnames';
import {firstObjectKey} from '../../helpers/objects';


Site.propTypes = {
  site: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
};

export function Site({site, actions, dispatch}) {
  const [isHovering, setHovering] = useState(false);

  const taskDetails = [
    'desired',
    'running',
    'pending',
    'deleting',
  ]
    .filter(x => site[x] && (x !== 'desired' || site.status !== 'deployed'))
    .map(x => `${x}: ${site[x]}`)
    .join(', ');

  return <div className={classNames(styles.site, firstObjectKey({
    [styles.failed]: ['failed', 'failed_build', 'failed_deploy'].includes(site.status),
    [styles.failedInProgress]: ['failed', 'failed_build', 'failed_deploy'].includes(site.prevStatus) && site.status === 'building',
    [styles.building]: site.status === 'building',
    [styles.failedInProgress]: ['failed', 'failed_build', 'failed_deploy'].includes(site.prevStatus) &&
      ['launched', 'launching', 'built', 'scaling_down'].includes(site.status),
    [styles.launching]: ['launched', 'launching', 'built', 'scaling_down'].includes(site.status),
    [styles.neverBuilt]: site.status === 'never_built',
    [styles.healthy]: ['healthy', 'deployed'].includes(site.status),
  }, styles.neverBuilt))}>
    <div className={styles.indicator} />
    <div className={styles.details} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      <div className={styles.header}>
        <h3 className={styles.name}>
          {site.site}

          <button type='button' onClick={() => dispatch(actions.editSite(site))} className={classNames(styles.editButton, {
            [styles.visible]: isHovering,
          })}>
            (edit)
          </button>
        </h3>
        <div className={styles.links}>
          <a href={site.url} rel='noopener noreferrer' target='_blank'>site</a>
          <a href={site.raygunURL} rel='noopener noreferrer' target='_blank'>raygun</a>
          <a href={site.ecsURL} rel='noopener noreferrer' target='_blank'>ecs</a>
        </div>
      </div>
      <p className={styles.status}>
        status: {site.prevStatus !== 'deployed' ? `${site.prevStatus}, ` : ''}{site.status}
        <span className={classNames(styles.version, {
          [styles.visible]: isHovering && site.version,
        })}>
          , version:{' '}
          <a href={site.gitlabURL} rel='noopener noreferrer'
             target='_blank'>{site.version}</a>
        </span>
      </p>
      {taskDetails ?
        <p className={styles.tasks}>{taskDetails}</p> :
        null}
    </div>
  </div>;
}
