import React, {useEffect, useReducer} from 'react';
import {Site} from '../Site';
import PropTypes from 'prop-types';
import styles from './index.css';

const getKey = name => `env-${name}-collapsed`;

function restoredState(name) {
  return localStorage.getItem(getKey(name)) === 'true';
}

function persistState(name, state) {
  localStorage.setItem(getKey(name), JSON.stringify(state));
}

function aggregateStatuses(sites) {
  return sites.reduce((acc, x) => {
    const s = normalizeStatus(x.status);

    return acc.set(s, (acc.get(s) || 0) + 1);
  }, new Map());
}

function normalizeStatus(status) {
  switch(status) {
    case 'deployed':
      return 'healthy';
    case 'building':
    case 'built':
    case 'launching':
    case 'scaling_down':
    case 'launched':
      return 'in progress';
    case 'failed_build':
    case 'failed_deploy':
      return 'failed';
    case 'never_built':
      return 'never built';
    default:
      return status;
  }
}

Environment.propTypes = {
  name: PropTypes.string.isRequired,
  sites: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
};

export function Environment({name, sites, dispatch, actions}) {
  let [isCollapsed, toggleCollapsed] = useReducer(state => !state, restoredState(name));

  let statuses = aggregateStatuses(sites);

  useEffect(() => {
    persistState(name, isCollapsed);
  }, [isCollapsed]);

  return <div className={styles.environment}>
    <div className={styles.header}>
      <h2 className={styles.title}>{name}</h2>
      <button onClick={toggleCollapsed} className={styles.toggle} type="button">({isCollapsed ? 'expand' : 'collapse'})</button>
    </div>
    <p className={styles.details}>
      {[...statuses.entries()]
        .map(([status, count]) => `${count} site${count > 1 ? 's' : ''} ${status}`)
        .join(', ')}
    </p>

    {isCollapsed ?
      null :
      sites.map(site => <Site key={site.siteID} site={site} dispatch={dispatch} actions={actions} />)}
  </div>;
}
