import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';

export const Input = forwardRef(({type = 'text', placeholder, label, name, defaultValue, ...props}, ref) => {
  return <label>
    {label || placeholder}
    <input type={type} placeholder={placeholder} name={name} className={styles.input}
                defaultValue={defaultValue} ref={ref}  {...props} /></label> ;
});

Input.displayName = 'Input';

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
};
